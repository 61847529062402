<template>
  <div :class="customClass" :id="elementId">
    <!--    <c-pre>{{ raceInformation }}</c-pre>-->
    <section class="start-time-container">
      <div class="start-time-main">
        <div class="main-item-left">
          <div class="left-title-box">
            <p>立即报名</p>
            <img @click="signupOrSearchDialogVisible = true" src="@/assets/img/home/right-registration.png" alt="">
          </div>
          <div class="left-time-box">
            <template v-if="isEmpty(raceInformation.signUpStartTime) && isEmpty(raceInformation.signUpEndTime)">
              <p style="">报名时间：0000-00-00 00:00 至 0000-00-00 00:00</p>
            </template>

            <template v-else>
              <p style="">报名时间：{{ raceInformation.signUpStartTime | dateTimeFormat('YYYY-MM-DD HH:mm') }} 至 {{ raceInformation.signUpEndTime | dateTimeFormat('YYYY-MM-DD HH:mm') }}</p>
            </template>
          </div>
        </div>
        <div class="main-item-middle"></div>
        <div class="main-item-right">
          <div class="right-title-box">
            <!-- <p>2021中田体育马拉松开赛还有</p>-->
            <p>{{ raceInformation.raceName }}开赛还有</p>
          </div>
          <div class="right-date-box">
            <span class="num-time" style="display:block;width: 52px;height: 52px;line-height: 52px;text-align: center;background-color: #F1F4FB;">{{ countdownTime.days | countdownTimeFormat }}</span>
            <span class="str-time" style="display:block;width: 52px;height: 52px;line-height: 52px;text-align: center;">天</span>
            <span class="num-time" style="display:block;width: 52px;height: 52px;background-color: #F1F4FB;line-height: 52px;text-align: center">{{ countdownTime.hours | countdownTimeFormat }}</span>
            <span class="str-time" style="display:block;width: 52px;height: 52px;line-height: 52px;text-align: center;">时</span>
            <span class="num-time" style="display:block;width: 52px;height: 52px;background-color: #F1F4FB;line-height: 52px;text-align: center">{{ countdownTime.minutes | countdownTimeFormat }}</span>
            <span class="str-time" style="display:block;width: 52px;height: 52px;line-height: 52px;text-align: center;">分</span>
            <span class="num-time" style="display:block;width: 52px;height: 52px;background-color: #F1F4FB;line-height: 52px;text-align: center">{{ countdownTime.seconds | countdownTimeFormat }}</span>
            <span class="str-time" style="display:block;width: 52px;height: 52px;line-height: 52px;text-align: center;">秒</span>
          </div>
          <template v-if="isEmpty(raceInformation.startTime)">
            <div style="text-align: center" class="right-time-box">开赛时间：0000-00-00 00:00</div>
          </template>

          <template v-else>
            <div style="text-align: center" class="right-time-box">开赛时间：{{ raceInformation.startTime | dateTimeFormat('YYYY-MM-DD HH:mm') }}</div>
          </template>
        </div>
      </div>
    </section>
    <signup-or-search-dialog :visible.sync="signupOrSearchDialogVisible"></signup-or-search-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import {sprintf}            from 'sprintf-js'
import moment               from 'moment'
import 'moment/locale/zh-cn'
import countdown            from 'countdown'
import 'moment-countdown'
///
import SignupOrSearchDialog from './components/signup-or-search-dialog/index.vue'

import {mapMutations, mapActions, mapState, mapGetters} from 'vuex'

export default {
  inject: {
    home: { from: 'home' },
  },
  // extends   : {},
  mixins    : [],
  components: { SignupOrSearchDialog },
  name      : 'RegistrationNowAndStartTimeComponent',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {
      timer                      : null,
      countdownTime              : {},
      signupOrSearchDialogVisible: false,
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    elementId() {return `RegistrationNowAndStartTimeComponent_${this._uid}`},
    customClass() {return ['RegistrationNowAndStartTimeComponent', this.className]},
    ...mapGetters({
      raceInformation: 'raceInformation',
    }),
  },
  methods : {},
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  mounted() {
    // let endTime = '2022-08-10 23:59:59'

    // let endTime = this.raceInformation.endTime
    let endTime = this.raceInformation.startTime
    console.log('【registration-now-and-start-time-component:91】', `endTime = `, endTime)
    console.log('【registration-now-and-start-time-component:94】', `moment(endTime) = `, moment(endTime).format('YYYY-MM-DD HH:mm:ss'))
    if (endTime) {
      //
    }
    else {
      endTime = moment().add(7, 'days').format('YYYY-MM-DD 00:00:00')
    }

    let r = moment().countdown(endTime, countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
    if (r.value < 0) {
      this.countdownTime = {
        'start'  : '0',
        'end'    : '0',
        'units'  : 0,
        'value'  : 0,
        'days'   : 0,
        'hours'  : 0,
        'minutes': 0,
        'seconds': 0,
      }
    }
    else {
      ///
      this.timer = null
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.countdownTime = moment().countdown(endTime, countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
        // console.log('【registration-now-and-start-time-component:217】', `this.countdownTime = `, this.countdownTime)
      }, 1000)
    }
  },
  filters: {
    countdownTimeFormat(data) {
      if (data === null || data === undefined) {return data}
      return sprintf('%02d', data)
    },
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "index.scss";

.RegistrationNowAndStartTimeComponent {
  background-color : transparent;
}
</style>
